import { domReady } from './utils.js';

domReady().then(() => {
    const form = document.querySelector('.form.-quick');
    form.addEventListener('submit', (e) => {
        e.preventDefault();

        const data = [];
        const formData = new FormData(form);
        for (const [key, value] of formData.entries()) {
            if (value) {
                data.push(`${key}=${value}`);
            }
        }

        window.open(`${window.location.href}/vaihtoautot?${data.join('&')}`);
    });

    const make = document.getElementById('make');
    const model = document.getElementById('model');
    make.addEventListener('change', (e) => {
        model.value = '';
        model.toggleAttribute('disabled', !e.target.value);

        if (e.target.value) {
            const models = model.querySelectorAll('option[data-make]');
            models.forEach((model) => {
                const isSelected = model.dataset.make !== e.target.value;
                model.toggleAttribute('hidden', isSelected);
                model.toggleAttribute('disabled', isSelected);
            });
        }
    });
});
